<template>
  <vx-card>
    <div class="grid-layout-container alignment-block" style="width:100%; margin-bottom: 40px; ">
      <vs-row>
        <vs-col vs-lg="12" vs-md="12" vs-xs="12">
          <vs-input
            placeholder="หัวข้อบทความ"
            v-model="blogTitle"
            style=" border: 0 solid rgba(0, 0, 0, 0); width: 100%;"
          />
        </vs-col>
      </vs-row>
    </div>
    <div class style="vertical-align:text-top ;width:100%;">
      <vs-row vs-w="12">
        <blog-image-upload :itemId="itemId"></blog-image-upload>
      </vs-row>
    </div>
  </vx-card>
</template>
<script>
import shapeFormat from "@/mixins/shapeFormat.js";
import BlogImageUpload from "./Components/BlogImageUpload.vue";
export default {
  mixins: [shapeFormat],
  data() {
    return {
      blogTitle: "",
      itemId: "",
      aProduct: {},
      categories: []
    };
  },
  async mounted() {
    this.aProduct = await this.getOne("/api/productitem/" + this.itemId);
    this.categories = await this.getList("/api/category");
  },
  created() {
    this.itemId = this.$route.params.blogid;
  },
  methods: {
    backtostock() {
      window.open("/backoffice/stock", "_blank");
    },
    backtodetail() {
      window.open("/backoffice/create-item/" + this.itemId, "_blank");
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    editItem(productItemId) {
      window.open("/backoffice/create-item/" + productItemId, "_blank");
      // this.$router.push("/backoffice/create-item/" + productItemId);
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    }
  },
  components: {
    BlogImageUpload
  }
};
</script>

<style >
</style>
